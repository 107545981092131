/** @jsx jsx */
import { Box, Heading, Text, jsx } from "theme-ui";
import styles from "./category-banner.style";
import useWindowSize from "../../hooks/useWindowSize";
import {useEffect, useState} from "react";

type PropsType = {
	title?: string;
	subtitle?: string;
	bgImage?: any;
};




const CategoryBanner: React.FC<PropsType> = ({
	title,
	bgImage,
}: any) => {

	const bgBannerImg = bgImage?.src ?? bgImage;
	const categoryName = title 
	? title?.replace(/Taki-aksesuar/,"Takı").replace(/Kanvas-tablo/,"Kanvas Tablo").replace(/-/g, "&").replace(/Pirinc/,"Pirinç")
									   .replace(/Ahsap/,"Ahşap").replace(/Canta/,"Çanta").replace(/obje/g, "Obje") : "";


	const [isMobile, setIsMobile] = useState(false)
	const windowSize = useWindowSize();
	useEffect(() => {
		if (windowSize < 1081) {
			setIsMobile(true)
		} else {
			setIsMobile(false)
		}
	}, [windowSize]);

	return (
		<div>
		<Box>
			<Heading sx={isMobile ? styles.headerCatMobile : styles.headerCat} style={{color:'black', textAlign:'center',fontFamily:'Exo 2',fontWeight:'400'}} as="h3">{categoryName}</Heading>
			</Box>
		<Box as="section" sx={styles.wrapper}>
			{bgBannerImg ? <img sx={styles.image} src={bgBannerImg} /> : null}
			<Box as="header" sx={styles.bannerContent}>
			</Box>
		</Box>
		</div>
	);
};

export default CategoryBanner;
