import React from 'react';
import PrimaryLayout from '../components/layout/primary/primary';
import styles from '../components/custom-style/about.module.css';
import get from 'lodash/get';
import Image from 'gatsby-image';
import Pratama from '../images/pratamaAboutResized-2.jpg'
import CategoryBanner from '../components/category-banner/category-banner';
import AboutImage1 from '../images/about-us (1).jpg'
import AboutImage2 from '../images/about-us (2).jpg'
import AboutImage3 from '../images/about-us (3).jpg'
import AboutImage4 from '../images/about-us (4).jpg'
import AboutImage5 from '../images/about-us (5).jpg'
import AboutImage6 from '../images/about-us (6).jpg'

class Contact extends React.Component {
  render() {
    const about = get(this, 'props.data.allContentfulAbout');
    console.log("about ", about)
    return (
      <PrimaryLayout>
        <div className={styles.indexWrapper}>
          <CategoryBanner bgImage={Pratama}/>
          <section className={styles.page}>
            <div>
              <h2>
                BARONGA’NIN HİKAYESİ
              </h2>
              <p>
                Baronga, Kökeni Bali Geleneğine Dayanan Akıl, Şans Ve Iyiliğin Sembolüdür. Amacı El Sanatı Vasıtasıyla
                Edindiği Bilgeliği, Güzelliği Ve Sevgiyi Etrafına Yaymaktır. Bu Tutkusu Ile Birlikte Kendine Özgü
                Düşünce Yapısını Var Eden 3 Ana Elementten Oluşur. Bunlar; Öz(Tin), Zanaat Ve Eşsizliktir.
              </p>

              <p>
                * Öz(Tin): Baronga, Animizm(Canlıcılık) Fikrinin Bir Yansıması Olarak Her Maddenin Kendine Ait Bir Ruhu
                Olduğuna Inanır. Tinsel Olanla Maddesel Olan Arasında Aslında Bir Fark Yoktur. Bitkiler, Ağaçlar,
                Metaller, Mineraller Ve Tüm Doğa Kutsaldır. İnsanoğlu Doğaya Ve Maddeye Şekil Vermeye Antik Dönemlerden
                Beri Devam Etmektedir. Kurduğu Yakın Ilişkide Insan, Kendi Bilincini, Enerjisini Ve Ruhunu Transandantal
                Bir Biçimde Doğaya Ve Doğadan Gelen Maddeye Aktarmaktadır. İnsan Tarafından Işlenen Her Nesne Kendine
                Özgü Bir Anlam Taşır. Ancak Tüm Bunların Temelindeki Ruh Tektir Ve O Da Evrene Aittir.
              </p>

              <p>
                * Zanaat: Baronga, Şehir Hayatı Ile Yetenekli Zanaat Ustalarının Arasındaki Bir Köprüdür. Bali’de Her
                Usta Zanaatkarın Kendine Özgü Bir Tarzı, Bilgeliği Ve Tekniği Vardır. Zanaatkar Olmanın Niteliği Ve
                Gizemini Yansıtabilmek Hikayenin Temelini Oluşturur. Ustalığın, Sanat Becerisinin Ve Yaratıcılığın
                Tarifini Yaparken, Zanaatkarlığın Büyüsünden De Bahsetmek Gerekir. Bunlara Bağlı Olarak ‘Hümanizma’ Ve
                ‘Eşitlik’ Baronga Değer Ve Prensiplerinin Merkezinde Yer Alır.
              </p>

              <p>
                * Eşsizlik: Baronga Düşünce Yapısını Oluşturan Bileşimin Son Halkası “Eşsizliktir.” Üstün Yetenekli
                Bali’li Ustalar Tarafından Işlenen Her Parça Tıpkı Evrenin Kendisi Gibi Eşsizdir. El Sanatı, Zaman-Mekan
                Döngüsü Içerisinde Insanlığın Ruhunu Evrene Yansıtan Bir Araçtır. Otantizm Olgusu, Zanaatkarın Ortaya
                Çıkardığı Sanat Ile Yaşadığı Coğrafyaya Ve Ait Olduğu Döneme Dair Ilişkiyi Yansıtmaktadır. Baronga’nın
                Ideali; Eşsiz Otantik El Sanatlarının, Global Bir Kültür Mirası Olarak Yarattığı Etkiyi Göstermek Ve Bu
                Eserleri Çevresiyle Paylaşmaktır.
              </p>
            </div>
            <div className={styles.container}>

              <article>
                <img
                  sx={AboutImage1}
                  alt="about-image-1"
                  className={styles.indeximages}
                />
              </article>
              <article>
                <img
                  sx={AboutImage2}
                  alt="about-image-2"
                  className={styles.indeximages}
                />
              </article>
              <article>
                <img
                  sx={AboutImage3}
                  alt="about-image-3"
                  className={styles.indeximages}
                />
              </article>
              <article>
                <img
                  sx={AboutImage4}
                  alt="about-image-4"
                  className={styles.indeximages}
                />
              </article>
              <article>
                <img
                  sx={AboutImage5}
                  alt="about-image-5"
                  className={styles.indeximages}
                />
              </article>
              <article>
                <img
                  sx={AboutImage6}
                  alt="about-image-6"
                  className={styles.indeximages}
                />
              </article>

            </div>
            <div>
              <p>
                Baronga’nın Yolculuğu 2014 Yılında Bali’ye Yaptığı Bir Seyahatte Büyük Maske Ustalarıyla Yolunun
                Kesişmesiyle Birlikte Başladı. O Günlerde Zanaatkarlarla Kurmaya Başladığı Köprünün Meyveleri 2016
                Yılında Cihangir Susam Sokak’ta Açılan Mağaza Ile Sanatseverlerin Beğenesine Sunuldu. 2020 Yılından
                Itibaren Ise Baronga, Yılın Farklı Dönemlerinde Düzenlenen Pek Çok Fuar Ve Festivalde Yer Alarak Pozitif
                Enerjili Ürünlerini Yeni Insanlarla Buluşturmaya Devam Ediyor.
              </p>
            </div>
          </section>
        </div>
      </PrimaryLayout>
    );
  }
}

export default Contact;
